.page-container {
    box-sizing: border-box;
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    padding: 1.5rem 1rem;
}

.inline-link {
    outline-offset: 4px;
}

.loading {
    font-size: 2rem;
    text-align: center;
    width: 100vw;
}

.text-page-container {
    background-color: #fafafa;
    border: 2px solid black;
    border-radius: 6px;
    font-size: 1.25rem;
    padding: 1rem;
    box-sizing: border-box;
    display: flex;
    flex-flow: column wrap;
    row-gap: 1rem;
}

.text-page-paragraph {
    display: inline-block;
    line-height: 1.5rem;
    margin: 0;
}

@media (min-width: 961px) {
    .text-page-container {
        align-self: center;
        max-width: 66%;
        padding: 1rem 2rem;
    }
}
