.main-heading-container {
    display: flex;
    justify-content: center;
    margin-bottom: 1.5rem;
    width: 100%;
}

h1 {
    font-size: 1.5rem;
    margin: 0;
    text-align: center;
}

h1:focus {
    outline-offset: 4px;
}

@media (min-width: 961px) {
    h1 {
        font-size: 1.8rem;
    }
}
