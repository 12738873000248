@font-face {
    font-family: 'Karla';
    src: local("src/assets/Karla-VariableFont_wght.ttf");
}

a:focus, a:hover {
    text-decoration: underline;
}

body {
    background-color: #eeeeec;
    font-family: Karla, sans-serif;
    margin: 0;
}

:focus {
    outline: 3px dashed #a98332;
}

.light-focus-outline:focus {
    outline-color: #c39738;
}

button {
    background-color: #062d47;
    border: none;
    border-radius: 1rem;
    box-sizing: border-box;
    color: white;
    font-family: inherit;
}

button:hover {
    background-color: #0a4471;
}

button:active {
    background-color: #031727;
}

input, textarea {
    background-color: #fafafa;
    border-radius: 6px;
    box-sizing: border-box;
    font-family: Karla, sans-serif;
    font-size: 1rem;
    padding: 1rem 0.66rem;
}

input {
    height: 2rem;
    /*padding: 0 0.3rem;*/
}

textarea {
    /*padding: 0.3rem;*/
    padding: 0.5rem 0.66rem;
}

fieldset {
    border-radius: 6px;
}

.visually-hidden {
    height: 1px;
    overflow: hidden;
    position: absolute;
    top: -10000px;
    width: 1px;
}

.display-none {
    display: none;
}
