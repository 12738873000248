.form-container {
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
}

.form {
    box-sizing: border-box;
    display: flex;
    flex-flow: column nowrap;
    font-weight: normal;
    height: 100%;
}

.form-field {
    margin: 0.3rem 0 1rem 0;
    width: 100%;
}

.invalid-form-field {
    background-color: #ffbfbf;
    outline: 3px solid #4f2727;
    padding-top: 0.3rem;
}

.form-field-error-message {
    box-sizing: border-box;
    display: block;
    margin: 0 0.3rem 0.3rem 0.3rem;
}

.radio-group {
    box-sizing: border-box;
    display: flex;
    flex-flow: row wrap;
    margin: 0 0 1rem 0;
    width: 100%;
}

.radio-option {
    display: flex;
    flex-flow: row nowrap;
    width: 50%;
}

.radio-label {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-flow: row nowrap;
    padding: 0 1rem;
}

.radio-label:focus-within {
    outline: 3px dashed #a98332;
    outline-offset: -4px;
}

.radio-input {
    margin: 0 0.5rem 0 0;
}

.radio-input:focus {
    outline: 0;
}

.form-input {
    width: 100%;
}

.form-input {
    outline-offset: -4px;
    width: 100%;
}

.form-textarea {
    min-height: 12rem;
    outline-offset: -4px;
    width: 100%;
}

.form-submit {
    font-size: 2rem;
    outline-offset: -6px;
    padding: 1rem 0;
    width: 100%;
}

.disabled-form-submit {
    background-color: #4F4F4F;
    cursor: not-allowed;
}

.disabled-form-submit:active {
    background-color: #4F4F4F;
}

.disabled-form-submit:hover {
    background-color: #4F4F4F;
}

@media (min-width: 1200px) {
    .form {
        flex-flow: row wrap;
        justify-content: space-between;
    }

    .form-container {
        padding: 0 12.5%;
    }

    .form-column-full {
        width: 100%;
    }

    .form-colum-half {
        width: 48%;
    }

    .form-column-two-thirds {
        width: 64%;
    }

    .form-column-one-third {
        width: 32%;
    }

    .form-submit {
        width: 30%;
    }
}
