nav {
    width: 100%;
}

/*Nav menu styles*/
.nav-menu-container {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    padding: 0;
}

.menu-disclosure {
    background-color: #062d47;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    font-size: 1.25rem;
    padding: 0;
    width: 100%;
}

.menu-button {
    align-items: center;
    display: flex;
    font-size: 1.5rem;
    height: 100%;
    margin: 0.33rem 0;
    padding: 0.25rem 1rem;
}

.menu-icon {
    padding-right: 0.5rem;
    width: 2.33rem;
}

/*List of links*/
.nav-list {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.nav-list-item {
    border-top: 3px solid #193d55;
    font-weight: normal;
    padding: 1rem;
}

/* Nav link styles */
.nav-link {
    align-items: center;
    color: white;
    display: flex;
    outline-offset: 8px;
    text-decoration: none;
}

.matched-link {
    color: #c39738;
    padding-left: 0;
}

@media (min-width: 961px) {
    .nav-link {
        outline-offset: -4px;
        padding: 1rem;
        text-decoration: none;
    }

    .nav-list {
        flex-direction: row;
        height: 100%;
        justify-content: flex-end;
    }

    .nav-list-item {
        border: none;
        display: flex;
        height: 100%;
        padding: 0;
    }
}
