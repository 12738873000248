.video-container {
    align-self: center;
    margin: 1rem;
    width: 100%;
}

.video-iframe {
    aspect-ratio: 16 / 9;
    border-radius: 6px;
    outline: none;
    width: 100%;
}

.transcript-container {
    display: flex;
    flex-flow: column;
}

.transcript-heading {
    align-self: center;
    margin: 0;
}

.transcript-speaker-name {
    font-weight: bold;
}

@media (min-width: 961px) {
    .video-container {
        max-width: 66%;
    }
}
