.user-message {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 1.5rem;
    padding: 1rem;
    width: 100%;
}

.success {
    background-color: #bfffbf;
    color: #002000;
}

.success:focus {
    outline: 3px dashed #274f27;
}

.error {
    background-color: #ffbfbf;
    color: #200000;
}

.error:focus {
    outline: 3px dashed #4f2727;
}
