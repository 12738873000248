.table-of-contents-heading {
    font-size: 1.5rem;
    margin: 0.25rem 0.25rem 0 0.25rem;
}

.table-of-contents-list {
    display: flex;
    flex-flow: column wrap;
    font-size: 1.25rem;
    margin: 0.5rem 0;
    padding: 0 0 0 3rem;
    row-gap: 0.75rem;
}

.law-page-section {
    display: flex;
    flex-flow: column wrap;
    row-gap: 1rem;
    margin-top: 1rem;
}

.law-page-section-heading {
    display: inline;
    font-size: 1.5rem;
    font-weight: 900;
    margin: 0.25rem 0.25rem 0 0.25rem;
    outline-offset: 4px;

}

.citation-link-container {
    display: flex;
    justify-content: flex-end;
    text-align: right;
}

.citation-link {
    font-size: 1rem;
    margin-top: 0.4rem;
}

.contact-list {
    margin: 0;
}

.contact-list-item {
    margin-bottom: 0.4rem;
}

@media (min-width: 961px) {
    .law-page-section {
        box-sizing: border-box;
    }

    .law-page-section > p {
        padding: 0 2rem;
    }
}
