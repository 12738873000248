.lds-ring {
    display: inline-block;
    height: 2rem;
    position: relative;
    width: 2rem;
}

.lds-ring div {
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border: 4px solid;
    border-color: #fff transparent transparent transparent;
    border-radius: 50%;
    box-sizing: border-box;
    display: block;
    height: 2rem;
    margin: 4px 0 0 1rem;
    position: absolute;
    width: 2rem;
}

.lds-ring .first {
    animation-delay: -0.45s;
}

.lds-ring .second {
    animation-delay: -0.3s;
}

.lds-ring .third {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
