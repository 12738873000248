.layout {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.main-content {
    height: 100%;
}

.router-outlet {
    height: 100%;
}

.skip-nav {
    align-items: center;
    background-color: #c39738;
    box-sizing: border-box;
    color: #062d47;
    display: flex;
    justify-content: center;
    top: -10rem;
}

.skip-nav:focus {
    height: 3rem;
    outline: 3px dashed #062d47;
    outline-offset: -4px;
    top: 0;
    width: 100vw;
}
